import React from 'react'
import { useSelector } from 'react-redux'

export default function FooterApp ({ children }) {
  const shouldRender = useSelector((state) => (
    state.app.get('renderFooter', true)
  ))
  return shouldRender ? (
    <div id="footer-app">
      <div className="footer-app">
        {children}
      </div>
    </div>
  ) : null
}
