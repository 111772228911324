import React, {
  useContext, useCallback, useId, useState, useEffect,
} from 'react'

import { HydrationContext } from './_HydrationContext'

function LazyRegistration ({ onRegister, children }) {
  useEffect(() => onRegister(), [])
  return children
}

export function WithLazy ({
  children, fallback,
}) {
  // creates a unique,
  // persistent id for this component
  const id = useId()
  // we need to set a local 'mounted' state that will trigger the useEffect,
  // which then signals to the hydrator this Suspension has been reconciled by React hydration.
  const [hasMounted, setDidMount] = useState(null)
  // consumes the hydration context api
  const hydrator = useContext(HydrationContext)
  // callback for when the inner Suspense component mounts
  const onRegister = useCallback(() => setDidMount(id), [])

  // when the inner Suspense component mounts,
  // we want to signal to the hydrator the component has been hydrated
  // and the React reconciliation verification is complete.
  useEffect(() => {
    if (hasMounted) {
      hydrator.setHydrated(id)
    }
  }, [hasMounted])

  // really bad practice to have render methods
  // executing functions like this, however, this function
  // does not alter state or trigger any kind of re-render so
  // it is considered safe. All we are doing is registering an ID
  // with out hydration manager array.
  hydrator.setInitialized(id)

  return (
    <React.Suspense fallback={fallback}>
      <LazyRegistration onRegister={onRegister}>
        {children}
      </LazyRegistration>
    </React.Suspense>
  )
}
export default WithLazy
