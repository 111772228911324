import { Map, List } from 'immutable'
import { SET_AUTH_LOGIN_SUCCESS } from 'services/auth/actions'
import _get from 'lodash/get'
import {
  emarsysCustomerLogin,
  emarsysReload,
  emarsysView,
  emarsysTag,
  emarsysCategory,
  emarsysSendCartStart,
  emarsysSendCartPurchase,
  emarsysSendEmail,
} from 'services/emarsys'
import { EN } from 'services/languages/constants'
import { SET_RESOLVER_LOCATION } from 'services/resolver/actions'
import {
  SET_CHECKOUT_USER_DATA,
  SET_CHECKOUT_ORDER_DATA,
} from 'services/checkout/actions'
import {
  SET_PLANS_SELECTION,
} from 'services/plans/actions'
import { CHANGE_USER_ACCOUNT_PLAN_TYPE_SET_DATA } from 'services/user-account/actions'
import { SET_EMAIL_SIGNUP_SUCCESS } from 'services/email-signup/actions'
import { SET_DETAIL_DATA } from 'services/detail/actions'
import { TYPE_CONTENT_SERIES } from 'services/content-type'
import { campaignShouldRender } from 'services/testarossa'
import { selectUserExternalId } from 'services/user/selectors'
import { getSrcLang } from 'services/lang'
import { selectGuestPhase, selectMembershipStatus } from 'services/user-account/selectors'
import { getPathLocationName } from 'services/url'
import { get as getConfig } from 'config'
import {
  SET_DEFAULT_EVENT,
  SET_EVENT_TAG_CLICKED,
  SET_EVENT_VIDEO_PLAYED,
  SET_EVENT_VIDEO_COMPLETED,
  SET_EVENT_VIDEO_ENGAGED,
  SET_EVENT_VIDEO_VISITED,
  SET_EVENT_SERIES_VISITED,
  SET_EVENT_PLAYLIST_VIDEO_ADDED,
  SET_EVENT_VIDEO_VIEW_QUALIFIED,
  SET_EVENT_VIDEO_AUDIO_CHANGED,
  SET_EVENT_GIFT_VIDEO_VIEWED,
  SET_EVENT_PAGE_VIEWED,
  SET_EVENT_EXPERIMENT_VARIANT_DECIDED,
  SET_EVENT_EXPERIMENT_DATA,
  SET_EVENT_USER_INTERACTION,
  SET_EVENT_USER_ADD_COMMENT,
  SET_EVENT_USER_MENU_ITEM_CLICKED,
  SET_EVENT_ACCOUNT_CANCEL_OFFER_POPUP,
  SET_EVENT_POPUP_MARKETING_PROMO,
  SET_EVENT_PAYPAL_BUTTON_DISPLAYED,
  SET_EVENT_PAYPAL_BUTTON_CLICKED,
  SET_EVENT_MEMBERSHIP_BUTTON_CLICKED,
  SET_EVENT_FOOTER_EMAIL_SUBMITTED,
  SET_EVENT_CART_ABANDON_EMAIL_SUBMITTED,
  SET_EVENT_GIFT_VIDEO_EMAIL_SUBMITTED,
  SET_EVENT_CART_CHECKOUT_STEP,
  SET_EVENT_EPISODE_VISITED,
  SET_EVENT_TILE_CLICK,
  SET_EVENT_SHARE_VIDEO,
  SET_EVENT_SHARE_PLAYLIST,
  SET_EVENT_NEW_TO_GAIA,
  SET_EVENT_NAVIGATION_USER_CLICK,
  SET_CREATE_ACCOUNT_PRECURSOR_EVENT,
  SET_EVENT_SIGN_UP,
  SET_EVENT_BEGIN_CHECKOUT,
  SET_EVENT_PURCHASE,
  SET_EVENT_PURCHASED_PLAN,
  SET_EVENT_EPISODE_LIST_PLAY_CLICKED,
  SET_EVENT_EPISODE_LIST_ADD_TO_PLAYLIST_CLICKED,
  SET_EVENT_EPISODE_LIST_TITLE_CLICKED,
  SET_DEFAULT_GA4_EVENT,
  SET_GA4_VARIABLES,
  SET_GA4_INIT_EVENT,
} from './actions'
import { buildEventVideoAudioChanged, sentAtDate } from '.'
import { sendEvent } from './send-event'

const config = getConfig()
const planGraphQLActive = !!_get(config, ['features', 'checkout', 'planGraphQL'])

const PUSH_DELAY_MS = 750
const GA4_VARIABLES_PUSH_DELAY_MS = 0

function emarsysTagVideoQualifiedView (store) {
  const { inboundTracking = Map(), detail = Map() } = store.getState()
  const scSrc = inboundTracking.getIn(['data', 'sc_src'])
  const scLid = inboundTracking.getIn(['data', 'sc_llid'])
  if (scSrc) {
    const campaignId = scSrc.substring(scSrc.lastIndexOf('_') + 1)
    if (campaignId) {
      const videoId = detail.getIn(['data', 'nid'])
      const timestamp = Date.now()
      let tag = `${campaignId}|${videoId}|${timestamp}`
      if (scLid) {
        const variantId = scLid
        tag = `${campaignId}-${variantId}|${videoId}|${timestamp}`
      }
      emarsysTag(tag)
    }
  }
}

function emarsysSendContentCategories (store, action) {
  const { data = {} } = action.payload
  const categories = []
  const adminCategory = _get(data, 'adminCategory.name')
  if (adminCategory) {
    categories.push(adminCategory)
  }
  const siteSegment = _get(data, 'siteSegment.name')
  if (siteSegment) {
    categories.push(siteSegment)
  }
  emarsysCategory(store, categories)
}

function emarsysSendCartStartData (store, action) {
  let { email } = action.payload
  const { plans = Map(), user = Map() } = store.getState()
  const selectedPlan = plans.get('selection')

  if (user.getIn(['data', 'mail'])) {
    email = user.getIn(['data', 'mail'])
  }

  if (!email || !Map.isMap(selectedPlan)) {
    return
  }

  const id = selectedPlan.get('id')
  const price = parseFloat(selectedPlan.getIn(['costs', 0], 0))
  const cartItems = [
    {
      item: id,
      quantity: 1,
      price,
    },
  ]

  emarsysSendCartStart(cartItems, email)
}

function emarsysSendCartPurchaseData (store, action) {
  const { billing, billingResponse, email } = action.payload
  const { plans } = store.getState()
  const selectedPlan = plans.get('selection')
  const id = selectedPlan.get('id')
  const price = parseFloat(selectedPlan.getIn(['costs', 0], 0))
  const purchase = {
    orderId: _get(billing, ['orderNumber']) || _get(billingResponse, 'orderNumber'),
    items: [
      {
        item: id,
        quantity: 1,
        price,
      },
    ],
  }

  emarsysSendCartPurchase(purchase, email)
}

function emarsysSendChangePlanData (store, action) {
  const orderNumber = Map.isMap(action.payload)
    ? action.payload.get('orderNumber')
    : ''

  if (!orderNumber) {
    return
  }

  const { plans, user } = store.getState()
  const selectedPlanSku = plans.getIn(['changePlanData', 'selectedPlan'])
  const availablePlans = plans.getIn(['data', 'plans'], List())
  const fullSelectedPlan = planGraphQLActive ? plans.getIn(['changePlanData', 'selectedPlan'], Map()) || Map() : availablePlans.find((plan) => plan.get('sku') === selectedPlanSku) || Map()
  const id = fullSelectedPlan.get('id', '')
  const price = parseFloat(fullSelectedPlan.getIn(['costs', 0], 0))
  const email = user.getIn(['data', 'mail'], '') || user.getIn(['data', 'email'], '')
  const purchase = {
    orderId: orderNumber,
    items: [
      {
        item: id,
        quantity: 1,
        price,
      },
    ],
  }

  emarsysSendCartPurchase(purchase, email)
}

function emarsysSendEmailSignupData (action) {
  const { email, success } = action.payload
  if (success) {
    emarsysSendEmail(email)
  }
}

function getTestarossaTestsData (store) {
  const { testarossa } = store.getState()

  if (Map.isMap(testarossa) && testarossa.get('initialized')) {
    // Note: Testarossa records return repeated elements
    const testarossaRecords = testarossa
      .get('records')
      .filter((record) => {
        const campaign = record.getIn(['campaign', 'friendlyId'])
        return campaignShouldRender({ campaign })
      })
      .toSet() // convert to Set to remove repeated elements
      .toList() // convert back to immutable List

    return testarossaRecords.map((record, index) => {
      const name = record.getIn(['campaign', 'friendlyId'])
      const variant = record.getIn(['variation', 'friendlyId'])
      return {
        [`test_name_${index}`]: name,
        [`test_variant_${index}`]: variant,
      }
    })
  }

  return new List()
}

function getUserTestsData (store) {
  const { optimizely } = store.getState()
  const experiments = optimizely.get('experiments')
  let userTests = getTestarossaTestsData(store)

  if (Map.isMap(experiments) && experiments.size > 0) {
    experiments.forEach((element) => {
      const index = userTests.size
      const name = element.get('campaignName')
      const variant = element.get('variationId')

      userTests = List([...userTests, {
        [`test_name_${index}`]: name,
        [`test_variant_${index}`]: variant,
      }])
    })
  }

  return userTests
}

// GA4 - Global/Default event
function pushGa4DefaultEvent (store, action) {
  const { event = Map() } = action.payload
  const { dataLayer } = global
  const state = store.getState()
  if (Map.isMap(event) && !event.isEmpty()) {
    const {
      app,
      user,
      resolver,
    } = state

    // get all tests data and collate it
    const allTests = getUserTestsData(store)
    const allTestsCollated = allTests.map((test2, index) => {
      return `${test2[`test_name_${index}`]} Var ${test2[`test_variant_${index}`]}`
    }).join(', ')

    const userId = event.get('user_id') || user.getIn(['data', 'uid']) || undefined
    const path = resolver.get('path')
    const membershipStatus = event.get('membership_status') || selectMembershipStatus(state)
    const guestPhase = selectGuestPhase(state)
    const externalId = selectUserExternalId(state)
    const timestamp = sentAtDate()
    const userLanguage = user.getIn(['data', 'language', 0]) || EN
    const uiElement = event.get('ui_element')
    const mediaLanguage = event.get('media_language')
    let data = event
      .mergeDeep(Array.from(allTests).reduce((accumulatedMap, test) => {
        return new Map([...accumulatedMap, ...new Map(test)])
      }, new Map()))
      .set('user_id', userId)
      .set('guest_phase', guestPhase)
      .set('membership_status', membershipStatus)
      .set('app_name', app.get('name'))
      .set('app_version', app.get('version'))
      .set('user_in_test', allTests.size > 0)
      .set('all_tests_collated', allTestsCollated)
      .set('user_language', userLanguage)
      .set('current_url', path)
      .set('gaia_uuid', externalId)
      .set('event_time', timestamp)
      .set('event_number', dataLayer.length)

    if (uiElement) {
      data = data.mergeDeep({
        ui_location: event.get('ui_location') || getPathLocationName(path),
        ui_element: uiElement,
      })
    }

    if (mediaLanguage) {
      data = data.mergeDeep({
        media_language: getSrcLang(mediaLanguage) || getSrcLang(userLanguage),
      })
    }

    setTimeout(() => {
      sendEvent(store, data)
    }, PUSH_DELAY_MS)
  }
}

function pushGa4Variables (store) {
  const state = store.getState()
  const { dataLayer } = global
  const {
    app,
    user,
    resolver,
  } = state

  const externalId = selectUserExternalId(state)
  const userLanguage = user.getIn(['data', 'language', 0]) || EN
  const membershipStatus = selectMembershipStatus(state)
  const guestPhase = selectGuestPhase(state)

  const data = {
    guest_phase: guestPhase,
    membership_status: membershipStatus,
    user_id: user.getIn(['data', 'uid']) || undefined,
    user_language: userLanguage,
    current_url: resolver.get('path'),
    app_name: app.get('name', 'web-app'),
    app_version: app.get('version'),
    gaia_uuid: externalId,
  }

  setTimeout(() => {
    dataLayer.push(data)
  }, GA4_VARIABLES_PUSH_DELAY_MS)
}

function pushGa4InitEvent () {
  const { dataLayer } = global

  const data = {
    event: 'gtm.init',
  }

  setTimeout(() => {
    dataLayer.push(data)
  }, PUSH_DELAY_MS)
}

function pushEventDefault (store, action) {
  const { event = Map() } = action.payload
  if (!event.isEmpty()) {
    const { resolver, user, page } = store.getState()
    const userId = user.getIn(['data', 'uid']) || undefined
    const path = resolver.get('path')
    const pageTitle = `${page.get('title')} | Gaia`
    const loggedInStatus = userId ? 'Logged In' : false

    const data = event
      .set('path', path)
      .set('userId', userId)
      .set('loggedInStatus', loggedInStatus)
      .set('pageTitle', pageTitle)
    setTimeout(() => {
      sendEvent(store, data)
    }, PUSH_DELAY_MS)
  }
}

function pushEventTagClicked (store, action) {
  const { event = Map() } = action.payload

  if (!event.isEmpty()) {
    const {
      resolver,
      user,
      page,
      auth,
    } = store.getState()
    const userId = user.getIn(['data', 'uid']) || undefined
    const path = resolver.get('path')
    const pageTitle = `${page.get('title')} | Gaia`
    const loggedInStatus = userId ? 'Logged In' : false
    const pageLanguage = page.get('lang', EN)
    const memberStatus = auth.get('jwt') ? 'member' : 'anonymous'

    const data = event
      .set('path', path)
      .set('userId', userId)
      .set('loggedInStatus', loggedInStatus)
      .set('pageTitle', pageTitle)
      .set('pageLanguage', pageLanguage)
      .set('memberStatus', memberStatus)
    setTimeout(() => {
      sendEvent(store, data)
    }, PUSH_DELAY_MS)
  }
}

function pushEventEpisodeList (store, action) {
  const { event = Map() } = action.payload

  if (!event.isEmpty()) {
    const {
      resolver,
      user,
      page,
      auth,
      detail,
    } = store.getState()
    const userId = user.getIn(['data', 'uid']) || undefined
    const path = resolver.get('path')
    const pageTitle = `${page.get('title')} | Gaia`
    const loggedInStatus = userId ? 'Logged In' : false
    const pageLanguage = page.get('lang', EN)
    const memberStatus = auth.get('jwt') ? 'member' : 'anonymous'
    const isSeriesPage = detail.getIn(['data', 'contentType']) === TYPE_CONTENT_SERIES
    const seriesId = isSeriesPage ? detail.getIn(['data', 'id']) : detail.getIn(['data', 'seriesId'])

    const data = event
      .set('path', path)
      .set('userId', userId)
      .set('loggedInStatus', loggedInStatus)
      .set('pageTitle', pageTitle)
      .set('pageLanguage', pageLanguage)
      .set('memberStatus', memberStatus)
      .set('parentSeries', seriesId)
      .set('contentType', 'video')

    setTimeout(() => {
      sendEvent(store, data)
    }, PUSH_DELAY_MS)
  }
}

function pushEventVideoVisited (store, action) {
  const { event } = action.payload || {}
  const { detail = Map() } = store.getState()
  const eventData = Map({
    parentSeries: detail.hasIn(['data', 'seriesTitle'])
      ? detail.getIn(['data', 'seriesTitle'])
      : undefined,
    parentSeriesId: detail.hasIn(['data', 'seriesId'])
      ? detail.getIn(['data', 'seriesId'])
      : undefined,
  })
  const dataLayerEvent = event.mergeDeep(eventData)
  setTimeout(() => {
    sendEvent(store, dataLayerEvent)
  }, PUSH_DELAY_MS)
}

function pushEventVideoPlay (store, action) {
  const { event } = action.payload || {}

  const { video = Map() } = store.getState()
  const eventData = Map({
    parentSeries: video.hasIn(['data', 'seriesTitle'])
      ? video.getIn(['data', 'seriesTitle'])
      : undefined,
    parentSeriesId: video.hasIn(['data', 'seriesId'])
      ? video.getIn(['data', 'seriesId'])
      : undefined,
  })
  const dataLayerEvent = event.mergeDeep(eventData)
  setTimeout(() => {
    sendEvent(store, dataLayerEvent)
  }, PUSH_DELAY_MS)
}

function pushEventVideoAudioChanged (store, action) {
  const {
    auth, app, page, location,
  } = store.getState()
  const { lang } = action.payload
  const data = {
    auth,
    app,
    page,
    location,
    lang,
  }
  const event = buildEventVideoAudioChanged(data)
  setTimeout(() => {
    sendEvent(store, event)
  }, PUSH_DELAY_MS)
}

function pushEventWithBasicUserInfo (store, action) {
  const { event } = action.payload || {}
  const { user, page } = store.getState()
  const userId = user.getIn(['data', 'uid']) || undefined
  const loggedInStatus = userId ? 'Logged In' : false

  const eventData = Map({
    loggedInStatus,
    pageLanguage: page.getIn(['lang']),
  })
  const dataLayerEvent = event.mergeDeep(eventData)
  setTimeout(() => {
    sendEvent(store, dataLayerEvent)
  }, PUSH_DELAY_MS)
}

function pushEventPageViewed (store, action) {
  const {
    auth, event, location, page,
  } = action.payload || {}
  const { resolver = Map(), detail = Map(), user } = store.getState()
  const userId = user.getIn(['data', 'uid']) || undefined
  const contentType = resolver.getIn(['data', 'content_type'])
  const eventData = Map({
    pageURL: location.pathname + location.search || '',
    pageTitle: `${page.get('title')} | Gaia`,
    loggedInStatus: auth.get('jwt') ? 'Logged In' : false,
    memberStatus: auth.get('jwt') ? 'member' : 'anonymous',
    parentSeries:
        contentType === 'video'
          ? detail.getIn(['data', 'seriesTitle'])
          : undefined,
    parentSeriesId:
        contentType === 'video'
          ? detail.getIn(['data', 'seriesId'])
          : undefined,
    userId,
  })
  const dataLayerEvent = event.mergeDeep(eventData)
  setTimeout(() => {
    sendEvent(store, dataLayerEvent)
  }, PUSH_DELAY_MS)
}

function pushEventGiftVideoViewed (store, action) {
  const { auth, event } = action.payload || {}

  const eventData = Map({
    loggedIn: auth.get('jwt') ? 'Logged In' : false,
  })
  const dataLayerEvent = event.mergeDeep(eventData)
  setTimeout(() => {
    sendEvent(store, dataLayerEvent)
  }, PUSH_DELAY_MS)
}

function pushEventEnriched (store, action) {
  const { event } = action.payload || {}
  const dataLayerEvent = Map({
    event: event.get('event'),
    eventCategory: event.get('eventCategory'),
    eventAction: event.get('eventAction'),
    eventLabel: event.get('eventLabel'),
  })
  setTimeout(() => {
    sendEvent(store, dataLayerEvent)
  }, PUSH_DELAY_MS)
}

/**
 * Redux middleware
 * @param {import('redux').Store} store A redux store
 * @returns {import('redux').Middleware} Redux middleware
 */
export default function middleware (store) {
  return (next) => (action) => {
    const { data } = action.payload || {}
    // dataLayer is special needs care
    switch (action.type) {
      case SET_DEFAULT_EVENT:
        pushEventDefault(store, action)
        break
      case SET_DEFAULT_GA4_EVENT:
        pushGa4DefaultEvent(store, action)
        break
      case SET_GA4_VARIABLES:
        pushGa4Variables(store, action)
        break
      case SET_GA4_INIT_EVENT:
        pushGa4InitEvent()
        break
      case SET_EVENT_TAG_CLICKED:
        pushEventTagClicked(store, action)
        break
      case SET_EVENT_EPISODE_LIST_PLAY_CLICKED:
      case SET_EVENT_EPISODE_LIST_ADD_TO_PLAYLIST_CLICKED:
      case SET_EVENT_EPISODE_LIST_TITLE_CLICKED:
        pushEventEpisodeList(store, action)
        break
      case SET_EVENT_VIDEO_VISITED:
      case SET_EVENT_EPISODE_VISITED:
        pushEventVideoVisited(store, action)
        break
      case SET_EVENT_VIDEO_PLAYED:
      case SET_EVENT_VIDEO_VIEW_QUALIFIED:
        pushEventVideoPlay(store, action)
        break
      case SET_EVENT_VIDEO_AUDIO_CHANGED:
        pushEventVideoAudioChanged(store, action)
        break
      case SET_EVENT_VIDEO_COMPLETED:
      case SET_EVENT_VIDEO_ENGAGED:
      case SET_EVENT_TILE_CLICK:
      case SET_EVENT_USER_ADD_COMMENT:
      case SET_EVENT_SHARE_VIDEO:
      case SET_EVENT_SHARE_PLAYLIST:
      case SET_EVENT_NEW_TO_GAIA:
      case SET_EVENT_NAVIGATION_USER_CLICK:
        pushEventWithBasicUserInfo(store, action)
        break
      case SET_EVENT_PAGE_VIEWED:
        pushEventPageViewed(store, action)
        break
      case SET_EVENT_GIFT_VIDEO_VIEWED:
        pushEventGiftVideoViewed(store, action)
        break
      case SET_EVENT_SERIES_VISITED:
      case SET_EVENT_PLAYLIST_VIDEO_ADDED:
      case SET_EVENT_EXPERIMENT_VARIANT_DECIDED:
      case SET_EVENT_EXPERIMENT_DATA:
      case SET_EVENT_USER_INTERACTION:
      case SET_EVENT_USER_MENU_ITEM_CLICKED:
      case SET_EVENT_CART_CHECKOUT_STEP:
      case SET_CREATE_ACCOUNT_PRECURSOR_EVENT:
      case SET_EVENT_SIGN_UP:
      case SET_EVENT_BEGIN_CHECKOUT:
      case SET_EVENT_PURCHASE:
      case SET_EVENT_PURCHASED_PLAN:
        setTimeout(() => {
          const { event = Map() } = action.payload
          sendEvent(store, event)
        }, PUSH_DELAY_MS)
        break
      case SET_EVENT_ACCOUNT_CANCEL_OFFER_POPUP:
      case SET_EVENT_POPUP_MARKETING_PROMO:
      case SET_EVENT_PAYPAL_BUTTON_DISPLAYED:
      case SET_EVENT_PAYPAL_BUTTON_CLICKED:
      case SET_EVENT_MEMBERSHIP_BUTTON_CLICKED:
      case SET_EVENT_FOOTER_EMAIL_SUBMITTED:
      case SET_EVENT_CART_ABANDON_EMAIL_SUBMITTED:
      case SET_EVENT_GIFT_VIDEO_EMAIL_SUBMITTED:
        pushEventEnriched(store, action)
        break
      default:
        // Do nothing.
        break
    }

    // Tracking
    switch (action.type) {
      case SET_AUTH_LOGIN_SUCCESS:
        emarsysCustomerLogin(store)
        break
      case SET_RESOLVER_LOCATION:
        // Needed to reload Emarsys for Web Channel due to SPA
        emarsysReload()
        break
      case SET_EVENT_VIDEO_VIEW_QUALIFIED:
        emarsysTagVideoQualifiedView(store)
        break
      case SET_DETAIL_DATA:
        emarsysView(store, _get(data, 'id'), { send: false })
        emarsysSendContentCategories(store, action)
        break
      case SET_CHECKOUT_USER_DATA:
      case SET_PLANS_SELECTION:
        setTimeout(() => {
          emarsysSendCartStartData(store, action)
        }, 500)
        break
      case SET_CHECKOUT_ORDER_DATA:
        setTimeout(() => {
          emarsysSendCartPurchaseData(store, action)
        }, 500)
        break
      case CHANGE_USER_ACCOUNT_PLAN_TYPE_SET_DATA:
        emarsysSendChangePlanData(store, action)
        break
      case SET_EMAIL_SIGNUP_SUCCESS:
        emarsysSendEmailSignupData(action)
        break
      default:
        // do nothing
        break
    }
    next(action)
  }
}
