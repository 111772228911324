/* eslint-disable react/jsx-filename-extension */
import React, { lazy } from 'react'
import _get from 'lodash/get'
import _includes from 'lodash/includes'
import {
  URL_PLAN_SELECTION,
  URL_PLAN_SELECTION_PLANS,
  URL_ACCOUNT_CREATION,
  URL_CART_ACCOUNT_CREATION_CREATE,
  URL_CART_BILLING,
  URL_CART_BILLING_PAYMENT,
  URL_CART_CONFIRMATION,
  URL_EMAIL_SETTINGS,
  URL_AMBASSADORS,
  URL_ACCOUNT_INVOICE,
  URL_REMOTE_ACCOUNT_CREATE,
  URL_CHECKOUT,
} from 'services/url/constants'
import { get as getConfig } from 'config'

// single page checkout toggle
const isSinglePageCheckoutActive = !!getConfig().features.singlePageCheckout

const LoadableFooter = lazy(
  () => import('components/Footer'),
)

function isFullPlayer (location) {
  const { query } = location
  return !!_get(query, 'fullplayer')
}

function footerHidden (location) {
  const pathnames = [
    URL_PLAN_SELECTION,
    URL_PLAN_SELECTION_PLANS,
    URL_ACCOUNT_CREATION,
    URL_CART_ACCOUNT_CREATION_CREATE,
    URL_CART_BILLING,
    URL_CART_BILLING_PAYMENT,
    URL_CART_CONFIRMATION,
    URL_EMAIL_SETTINGS,
    URL_AMBASSADORS,
    URL_ACCOUNT_INVOICE,
    URL_REMOTE_ACCOUNT_CREATE,
    '/get-started',
    '/give',
    '/give/email-submit',
    '/give/video',
    '/cancel-offer-modal',
    '/roku-billing',
    '/gaia-now',
    '/live',
    '/who-is-watching',
    ...(isSinglePageCheckoutActive ? [URL_CHECKOUT] : []),
  ]

  if (location.pathname.startsWith('/share/') && !!getConfig().features.sharePageV3) {
    return true
  }

  return _includes(pathnames, location.pathname)
}

function getFooter (props) {
  const { history, location } = props
  if (isFullPlayer(location) || footerHidden(location)) {
    return null
  }
  return (
    <LoadableFooter location={location} history={history} />
  )
}

export default [
  {
    path: '*',
    component: getFooter,
  },
]
