import strictUriEncode from 'strict-uri-encode'
import { List } from 'immutable'
import { post as apiPost, TYPE_BROOKLYN_JSON } from 'api-client'
import _get from 'lodash/get'
import { setDefaultGaEvent, setDefaultGa4Event } from 'services/event-tracking/actions'
import { HIDE_WATCHED_EVENT, ITEM_REMOVED_FROM_HISTORY_GA4_EVENT } from 'services/event-tracking'
import {
  SAVE_HIDE_WATCHED,
  DONE_SAVE_HIDE_WATCHED,
} from './actions'

export default function batchSaveHideWatched ({ after }) {
  return after(SAVE_HIDE_WATCHED, async ({ state, dispatch }) => {
    const { auth, hideWatched } = state
    const promises = hideWatched.get('data', List())
      .map((item) => {
        const event = HIDE_WATCHED_EVENT
          .set('eventLabel', item.title)
        const eventGA4 = ITEM_REMOVED_FROM_HISTORY_GA4_EVENT
          .mergeDeep({
            content_name: _get(item, 'title'),
            content_id: _get(item, 'id'),
            content_type: _get(item, 'contentType'),
            parent_series_name: _get(item, 'seriesTitle') ? _get(item, 'seriesTitle') : undefined,
            parent_series_id: _get(item, 'seriesId') && _get(item, 'seriesId') !== -1 ? _get(item, 'seriesId') : undefined,
          })

        // GA3 event
        dispatch(setDefaultGaEvent(event))

        // GA4 event
        dispatch(setDefaultGa4Event(eventGA4))

        return apiPost(
          `user/flag-watched/${strictUriEncode(item.id)}`,
          { hiddenByUser: true },
          { auth },
          TYPE_BROOKLYN_JSON,
        )
      })
    await Promise.all(promises)
    dispatch({ type: DONE_SAVE_HIDE_WATCHED })
  })
}
