import React, { lazy, useEffect, useState } from 'react'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { compose } from 'recompose'
import { getBoundActions } from 'actions'
import { get as getConfig } from 'config'
import { getPageTitle } from 'services/page'
import { connect as connectRedux } from 'react-redux'
import { connect as connectAlertBar } from 'components/AlertBar/connect'
import GoogleTagManager from 'components/GoogleTagManager'
import InboundTracking from 'components/InboundTracking'
import Interstitial from 'components/Interstitial'
import Resolver from 'components/Resolver'
import Toasty from 'components/Toasty'

const config = getConfig()
const popupFeatureToggle = _get(config, ['features', 'marketing', 'specialOfferPromoPopup'])
const dataPrivacyCompliance = _get(config, ['features', 'anonymousUser', 'dataPrivacyCompliance'])
const oneTrustEnabled = !!_get(config, ['features', 'oneTrust'])

const LoadableMainLoader = lazy(
  () => import('components/MainLoader'),
)

const LoadablePopupMarketingPromo = lazy(
  () => import('components/PopupMarketingPromo'),
)

const LoadableCookieBanner = lazy(
  () => import('components/CookieBanner'),
)

function renderPopup (location) {
  if (popupFeatureToggle) {
    return (
      <LoadablePopupMarketingPromo
        location={location}
        timer={30000}
      />
    )
  }
  return null
}

/**
 * The main section of the app
 * @type {React.ReactElement}
 * @param {Object} props The props
 */
function Main (props) {
  const {
    initAlertBarTypeLanguage,
    bootstrapComplete,
    renderApp,
    location,
    children,
    history,
    title,
    client, // the Apollo Client
    auth,
  } = props
  const authLoginSuccess = auth.get('success')
  const [loginSuccess, setLoginSuccess] = useState(authLoginSuccess)

  useEffect(() => {
    if (bootstrapComplete) {
      initAlertBarTypeLanguage()
    }
  }, [bootstrapComplete])

  useEffect(() => {
    // anytime the user logs in, reset the Apollo Client InMemoryCache
    if (!loginSuccess && authLoginSuccess && client?.clearStore) {
      setLoginSuccess(true)
      client.clearStore()
    }
  }, [authLoginSuccess])

  return (
    <div id="main-app">
      <div className="main">
        <GoogleTagManager location={location} pageTitle={title} />
        <InboundTracking location={location} />
        <main className="main__content">
          {renderApp ? children : null}
        </main>
        {bootstrapComplete ? (
          <React.Suspense>
            <LoadableMainLoader location={location} history={history} />
            {renderPopup(location)}
            {dataPrivacyCompliance && !oneTrustEnabled ? (
              <LoadableCookieBanner location={location} />
            ) : null}
            <Interstitial />
            <Resolver history={history} />
            <Toasty />
          </React.Suspense>
        ) : null}
      </div>
    </div>
  )
}

Main.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  client: PropTypes.object,
  auth: ImmutablePropTypes.map.isRequired,
}

export default compose(
  connectAlertBar(),
  connectRedux(
    (state) => ({
      bootstrapComplete: state.app.get('bootstrapComplete'),
      location: state.resolver.get('location'),
      renderApp: state.app.get('renderApp'),
      title: getPageTitle(state.page),
      auth: state.auth,
    }),
    (dispatch) => {
      const actions = getBoundActions(dispatch)
      return { initAlertBarTypeLanguage: actions.alertBar.initAlertBarTypeLanguage }
    },
  ),
)(Main)
