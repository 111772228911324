import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'
import _isUndefined from 'lodash/isUndefined'
import _parseInt from 'lodash/parseInt'
import _split from 'lodash/split'
import defaultConfig from './default.json'
import { get as getEnv } from '../environment'

let config = {}

/** True when running in a browser */
// eslint-disable-next-line prefer-destructuring
const BROWSER = process.env.BROWSER

/**
 * Parse an evironment variable into an array
 * @param {String} envValue The environment value
 * @param {Array} defaultValue A default values if the environment variable is undefined
 * @param {String} delimter A delimiter to split the enviorment value into an array
 * @returns {Array} The values as an array
 */
export function parseEnvArray (envValue, defaultValue, delimter = ',') {
  return !_isUndefined(envValue) ? _split(envValue, delimter) : defaultValue
}

/**
 * Parse an evironment variable into a boolean
 * @param {String} envValue The environment value
 * @param {Boolean} defaultValue A default values if the environment variable is undefined
 * @returns {Boolean} The value as a boolean
 */
export function parseEnvBool (envValue, defaultValue) {
  return !_isUndefined(envValue) ? _parseInt(envValue, 2) : defaultValue
}

/**
 * Parse an evironment variable into a number
 * @param {String} envValue The environment value
 * @param {Number}} defaultValue A default values if the environment variable is undefined
 * @returns {Number} The value as a boolean
 */
export function parseEnvInt (envValue, defaultValue) {
  return !_isUndefined(envValue) ? _parseInt(envValue) : defaultValue
}

/**
 * Parse an evironment variable into a float
 * @param {String} envValue The environment value
 * @param {Float}} defaultValue A default values if the environment variable is undefined
 * @returns {Float} The value as a float
 */
export function parseEnvFloat (envValue, defaultValue) {
  return !_isUndefined(envValue) ? parseFloat(envValue) : defaultValue
}

/**
 * Get environment variables using the current enviroment (browser or server)
 * @returns {Object} Evironment variables
 */
function getEnvironment () {
  if (BROWSER) {
    // eslint-disable-next-line global-require
    const { getAppData } = require('react-universal/browser')
    return _get(getAppData(), ['hydrate', 'env'], {})
  }
  return process.env || {}
}

/**
 * Initialize the config by parsing the environment variable values
 * @returns {Object} A config object
 */
export function init () {
  config = _cloneDeep(defaultConfig)
  const envVars = getEnvironment()
  // Set additional environment vars.
  config.env = getEnv()
  config.apiClient.backoffExponent = parseEnvInt(
    envVars.API_CLIENT_BACKOFF_EXPONENT,
    config.apiClient.backoffExponent,
  )
  config.apiClient.retryDelay = parseEnvInt(
    envVars.API_CLIENT_RETRY_DELAY,
    config.apiClient.retryDelay,
  )
  config.apiClient.timeout = parseEnvInt(
    envVars.API_CLIENT_TIMEOUT,
    config.apiClient.timeout,
  )
  // flag used to show a 'temporarily unavailable' screen
  // when an external service is down or having issues.
  config.serviceUnavailable = parseEnvInt(
    envVars.SERVICE_UNAVAILABLE,
    config.serviceUnavailable,
  )

  config.cloudfrontDistributionId = envVars.CLOUDFRONT_DISTRIBUTION_ID
    || config.cloudfrontDistributionId
  config.log.browser.level = envVars.LOG_BROWSER_LEVEL || config.log.browser.level
  config.log.browser.outputMode = envVars.LOG_BROWSER_OUTPUT_MODE || config.log.browser.outputMode
  config.log.server.level = envVars.LOG_SERVER_LEVEL || config.log.server.level
  config.log.server.outputMode = envVars.LOG_SERVER_OUTPUT_MODE || config.log.server.outputMode
  config.nodePath = envVars.NODE_PATH || config.nodePath
  config.userAccountHostname = envVars.USER_ACCOUNT_HOSTNAME || config.userAccountHostname
  config.server.timeout = parseEnvInt(
    envVars.SERVER_TIMEOUT,
    config.server.timeout,
  )
  config.server.keepAliveTimeout = parseEnvInt(
    envVars.SERVER_KEEPALIVE_TIMEOUT,
    config.server.keepAliveTimeout,
  )
  // Assets Server
  config.servers.brooklyn = envVars.SERVERS_BROOKLYN || config.servers.brooklyn
  config.servers.brooklynInternal = envVars.SERVERS_BROOKLYN_INTERNAL
    || config.servers.brooklynInternal
  config.servers.auth = envVars.SERVERS_AUTH || config.servers.auth
  config.servers.videoAnalytics = envVars.SERVERS_VIDEO_ANALYTICS || config.servers.videoAnalytics
  config.servers.testarossa = envVars.SERVERS_TESTAROSSA || config.servers.testarossa
  config.servers.graphql = envVars.SERVERS_GRAPHQL || config.servers.graphql
  // eslint-disable-next-line max-len
  config.servers.graphqlInternal = envVars.SERVERS_GRAPHQL_INTERNAL || config.servers.graphqlInternal
  config.hostname = envVars.HOSTNAME || config.hostname
  config.origin = envVars.ORIGIN || config.origin
  config.originReact = envVars.ORIGIN_REACT || config.originReact
  config.httpPort = envVars.HTTP_PORT || config.httpPort
  config.adminCmsUrl = envVars.ADMIN_CMS_URL || config.adminCmsUrl
  config.adminAppUrl = envVars.ADMIN_APP_URL || config.servers.adminAppUrl
  config.httpPortReact = envVars.HTTP_PORT_REACT || config.httpPortReact
  config.renderConcurrency = parseEnvInt(
    envVars.RENDER_CONCURRENCY,
    config.renderConcurrency,
  )
  config.marketingLayerUrl = envVars.MARKETING_LAYER_URL || config.marketingLayerUrl
  config.billingIframeOrigin = envVars.BILLING_IFRAME_ORIGIN || config.billingIframeOrigin
  config.baseHref = envVars.BASE_HREF || config.baseHref
  config.robotsCanIndex = parseEnvInt(
    envVars.ROBOTS_CAN_INDEX,
    config.robotsCanIndex,
  )
  config.robotsNoIndexHeader = parseEnvInt(
    envVars.ROBOTS_NO_INDEX_HEADER,
    config.robotsNoIndexHeader,
  )
  config.gtmId = envVars.GTM_ID || config.gtmId
  config.gtmHost = envVars.GTM_HOST || config.gtmHost

  config.emarsysTestmode = parseEnvBool(envVars.EMARSYS_TESTMODE || config.emarsysTestmode)
  config.emarsysTestmodeUserId = config.emarsysTestmode
    ? parseEnvInt(envVars.EMARSYS_TESTMODE_USER_ID || config.emarsysTestmodeUserId)
    : parseEnvInt(envVars.EMARSYS_TESTMODE_USER_ID)
  config.emarsysTestmodeUserEmail = config.emarsysTestmode
    ? envVars.EMARSYS_TESTMODE_USER_EMAIL || config.emarsysTestmodeUserEmail
    : envVars.EMARSYS_TESTMODE_USER_EMAIL

  config.appLang = envVars.APP_LANG || config.appLang
  config.appLocale = envVars.APP_LOCALE || config.appLocale
  config.appCountry = envVars.APP_COUNTRY || config.appCountry
  config.googleRecaptchaSiteKey = envVars.GOOGLE_RECAPTCHA_SITE_KEY || config.googleRecaptchaSiteKey
  config.googleRecaptchaSecretKey = envVars.GOOGLE_RECAPTCHA_SECRET_KEY
    || config.googleRecaptchaSecretKey
  config.googleRecaptchaApiUrl = envVars.GOOGLE_RECAPTCHA_API_URL || config.googleRecaptchaApiUrl
  config.googleRecaptchaThreshold = envVars.GOOGLE_RECAPTCHA_THRESHOLD
    || config.googleRecaptchaThreshold

  config.fingerPrintJsApiKey = envVars.FINGERPRINT_JS_API_KEY || config.fingerPrintJsApiKey
  config.fingerPrintJsSubdomain = envVars.FINGERPRINT_JS_SUBDOMAIN || config.fingerPrintJsSubdomain

  config.dataDomeApiKey = envVars.DATA_DOME_API_KEY || config.dataDomeApiKey
  config.dataDomeAjaxListenerPath = envVars.DATA_DOME_AJAX_LISTENER_PATH
    || config.dataDomeAjaxListenerPath

  config.oneTrustDataDomainScript = envVars.ONE_TRUST_DATA_DOMAIN_SCRIPT
    || config.oneTrustDataDomainScript

  config.adyenMerchantAccount = envVars.ADYEN_MERCHANT_ACCOUNT || config.adyenMerchantAccount
  config.adyenEnvironment = envVars.ADYEN_ENVIRONMENT || config.adyenEnvironment
  config.adyenClientKey = envVars.ADYEN_CLIENT_KEY || config.adyenClientKey
  config.adyenReturnUrl = envVars.ADYEN_RETURN_URL || config.adyenReturnUrl
  config.adyenGooglepayEnvironment = envVars.ADYEN_GOOGLEPAY_ENVIRONMENT
    || config.adyenGooglepayEnvironment

  config.anonymousUuidSecret = envVars.ANONYMOUS_UUID_SECRET || config.anonymousUuidSecret

  config.appCache = config.appCache || {}
  config.appCache.maxAge = parseEnvInt(
    envVars.APP_CACHE_MAX_AGE,
    config.appCache.maxAge,
  )

  config.stsCache = config.stsCache || {}
  config.stsCache.maxAge = parseEnvInt(
    envVars.APP_STS_HEADER_MAX_AGE,
    config.stsCache.maxAge,
  )

  config.assetsCache = config.assetsCache || {}
  config.assetsCache.maxAge = parseEnvInt(
    envVars.ASSETS_CACHE_MAX_AGE,
    config.assetsCache.maxAge,
  )

  config.youboraAccountCode = envVars.YOUBORA_ACCOUNT_CODE || config.youboraAccountCode

  config.debug = config.debug || {}
  config.debug.showUsername = parseEnvInt(
    envVars.DEBUG_SHOW_USERNAME,
    config.debug.showUsername,
  )
  config.debug.react = config.debug.react || {}
  config.debug.react.performance = parseEnvInt(
    envVars.DEBUG_REACT_PERFORMANCE,
    config.debug.react.performance,
  )

  config.redirect = config.redirect || {}
  config.redirect.https = parseEnvInt(
    envVars.REDIRECT_HTTPS,
    config.redirect.https,
  )
  config.redirect.hostname = parseEnvInt(
    envVars.REDIRECT_HOSTNAME,
    config.redirect.hostname,
  )

  config.appMaintenance = config.appMaintenance || {}
  config.appMaintenance.enabled = parseEnvInt(
    envVars.APP_MAINTENANCE_ENABLED,
    config.appMaintenance.enabled,
  )
  config.appMaintenance.maxAge = parseEnvInt(
    envVars.APP_MAINTENANCE_MAX_AGE,
    config.appMaintenance.maxAge,
  )

  config.siteName = envVars.SITE_NAME || config.siteName

  config.facebook = config.facebook || {}
  config.facebook.appId = envVars.FACEBOOK_APP_ID || config.facebook.appId
  config.facebook.loginAppId = envVars.FACEBOOK_LOGIN_APP_ID || config.facebook.loginAppId

  config.brightcove = config.brightcove || {}
  config.brightcove.account = envVars.BRIGHTCOVE_ACCOUNT || config.brightcove.account
  config.brightcove.player = envVars.BRIGHTCOVE_PLAYER || config.brightcove.player
  config.brightcove.liveplayer = envVars.LIVE_ACCESS_VIDEO_PLAYER || config.brightcove.liveplayer
  config.brightcove.byterangedelivery = parseEnvInt(
    envVars.BRIGHTCOVE_BYTE_RANGE_DELIVERY,
    config.brightcove.byterangedelivery,
  )
  config.brightcove.hlsversion = parseEnvInt(
    envVars.BRIGHTCOVE_HLS_VERSION,
    config.brightcove.hlsversion,
  )

  config.changePlanAvailabilitySkus = parseEnvArray(
    envVars.CHANGE_PLAN_AVAILABILITY_SKUS,
    config.changePlanAvailabilitySkus,
  )

  // Services
  config.services = config.services || {}
  config.services.memberHome = config.services.memberHome || {}
  config.services.memberHome.path = envVars.SERVICES_MEMBER_HOME_PATH
    || config.services.memberHome.path

  // Everflow
  config.everflow = config.everflow || {}
  config.everflow.defaultOfferId = envVars.EVERFLOW_DEFAULT_OFFER_ID
  || config.everflow.defaultOfferId

  // Share v3 Additional Media Ids
  config.contentIds = config.contentIds || {}
  config.contentIds.watchOnDemand = parseEnvInt(
    envVars.CONTENT_ID_WATCH_ON_DEMAND || config.contentIds.watchOnDemand,
  )
  config.contentIds.missingLinks = parseEnvInt(
    envVars.SHARE_CONTENT_ID_MISSING_LINKS || config.contentIds.missingLinks,
  )
  config.contentIds.nuevo = parseEnvInt(envVars.SHARE_CONTENT_ID_NUEVO
    || config.contentIds.nuevo)
  config.contentIds.transcendence = parseEnvInt(
    envVars.SHARE_CONTENT_ID_TRANSCENDENCE || config.contentIds.transcendence,
  )
  config.contentIds.godsWereAstronauts = parseEnvInt(envVars.SHARE_CONTENT_ID_GODS_WERE_ASTRONAUTS
    || config.contentIds.godsWereAstronauts)
  config.contentIds.leclosionDuCoeur = parseEnvInt(envVars.SHARE_CONTENT_ID_LECLOSION_DU_COEUR
    || config.contentIds.leclosionDuCoeur)
  config.contentIds.emotion = parseEnvInt(
    envVars.SHARE_CONTENT_ID_EMOTION || config.contentIds.emotion,
  )
  config.contentIds.ancientCiv = parseEnvInt(
    envVars.SHARE_CONTENT_ID_ANCIENT_CIV || config.contentIds.ancientCiv,
  )

  // Plan Ids
  config.planIds = config.planIds || {}
  config.planIds.threeMonth = envVars.PLAN_ID_THREE_MONTH || config.planIds.threeMonth
  config.planIds.monthly = envVars.PLAN_ID_MONTHLY || config.planIds.monthly
  config.planIds.annual = envVars.PLAN_ID_ANNUAL || config.planIds.annual
  config.planIds.live = envVars.PLAN_ID_LIVE || config.planIds.live
  config.planIds.liveDiscounted = envVars.PLAN_ID_LIVE_DISCOUNTED || config.planIds.liveDiscounted
  config.planIds.fourteenDay99Cent = envVars.PLAN_ID_FOURTEEN_DAY_99_CENT
    || config.planIds.fourteenDay99Cent
  config.planIds.oneWeekFreeTrialToMonthly = envVars.PLAN_ID_ONE_WEEK_FREE_TRIAL_TO_MONTHLY
    || config.planIds.oneWeekFreeTrialToMonthly
  config.planIds.oneWeekFreeTrialToAnnual = envVars.PLAN_ID_ONE_WEEK_FREE_TRIAL_TO_ANNUAL
    || config.planIds.oneWeekFreeTrialToAnnual
  config.planIds.twoWeekFreeTrialToMonthly = envVars.PLAN_ID_TWO_WEEK_FREE_TRIAL_TO_MONTHLY
    || config.planIds.twoWeekFreeTrialToMonthly
  config.planIds.twoWeekFreeTrialToAnnual = envVars.PLAN_ID_TWO_WEEK_FREE_TRIAL_TO_ANNUAL
    || config.planIds.twoWeekFreeTrialToAnnual
  config.planIds.compPlan = envVars.PLAN_ID_COMP
    || config.planIds.compPlan
  config.planIds.legacyMonthly = envVars.PLAN_ID_LEGACY_MONTHLY
    || config.planIds.legacyMonthly
  config.planIds.legacyAnnual = envVars.PLAN_ID_LEGACY_ANNUAL
    || config.planIds.legacyAnnual
  config.planIds.buyOneGetOneFree = envVars.PLAN_ID_BUY_ONE_GET_ONE_FREE
    || config.planIds.buyOneGetOneFree
  config.planIds.buyTwoGetTwoFree = envVars.PLAN_ID_BUY_TWO_GET_TWO_FREE
    || config.planIds.buyTwoGetTwoFree
  config.planIds.freeTrial = envVars.PLAN_ID_FREE_TRIAL || config.planIds.freeTrial
  config.planIds.oneDollarThirtyDaysToMonthly = envVars.PLAN_ID_ONE_DOLLAR_THIRTY_DAYS_TO_MONTHLY
    || config.planIds.oneDollarThirtyDaysToMonthly
  config.planIds.threeMonthGift = envVars.PLAN_ID_THREE_MONTH_GIFT || config.planIds.threeMonthGift
  config.planIds.annualGift = envVars.PLAN_ID_ANNUAL_GIFT || config.planIds.annualGift
  config.planIds.liveGift = envVars.PLAN_ID_LIVE_GIFT || config.planIds.liveGift
  config.planIds.bulkThreeMonthGift = envVars.PLAN_ID_BULK_THREE_MONTH_GIFT
    || config.planIds.bulkThreeMonthGift
  config.planIds.bulkAnnualGift = envVars.PLAN_ID_BULK_ANNUAL_GIFT || config.planIds.bulkAnnualGift
  config.planIds.bulkLiveGift = envVars.PLAN_ID_BULK_LIVE_GIFT || config.planIds.bulkLiveGift
  config.planIds.summitMonthly = envVars.PLAN_ID_SUMMIT_MONTHLY || config.planIds.summitMonthly
  config.planIds.summitMonthly2 = envVars.PLAN_ID_SUMMIT_MONTHLY_2 || config.planIds.summitMonthly2
  config.planIds.summitMonthly3 = envVars.PLAN_ID_SUMMIT_MONTHLY_3 || config.planIds.summitMonthly3
  config.planIds.summitMonthly4 = envVars.PLAN_ID_SUMMIT_MONTHLY_4 || config.planIds.summitMonthly4
  config.planIds.summitMonthly5 = envVars.PLAN_ID_SUMMIT_MONTHLY_5 || config.planIds.summitMonthly5
  config.planIds.summitMonthly6 = envVars.PLAN_ID_SUMMIT_MONTHLY_6 || config.planIds.summitMonthly6
  config.planIds.summitAnnual = envVars.PLAN_ID_SUMMIT_ANNUAL || config.planIds.summitAnnual
  config.planIds.summitAnnual2 = envVars.PLAN_ID_SUMMIT_ANNUAL_2 || config.planIds.summitAnnual2
  config.planIds.summitAnnual3 = envVars.PLAN_ID_SUMMIT_ANNUAL_3 || config.planIds.summitAnnual3
  config.planIds.summitAnnual4 = envVars.PLAN_ID_SUMMIT_ANNUAL_4 || config.planIds.summitAnnual4
  config.planIds.summitAnnual5 = envVars.PLAN_ID_SUMMIT_ANNUAL_5 || config.planIds.summitAnnual5
  config.planIds.summitAnnual6 = envVars.PLAN_ID_SUMMIT_ANNUAL_6 || config.planIds.summitAnnual6
  config.planIds.oneMonthTrialToAnnual = envVars.PLAN_ID_ONE_MONTH_TRIAL_TO_ANNUAL
    || config.planIds.oneMonthTrialToAnnual
  config.planIds.increasedPriceMonthly = envVars.PLAN_ID_INCREASED_PRICE_MONTHLY
    || config.planIds.increasedPriceMonthly
  config.planIds.increasedPriceOneWeekFreeTrialToMonthly = envVars
    .PLAN_ID_INCREASED_PRICE_ONE_WEEK_FREE_TRIAL_TO_MONTHLY
    || config.planIds.increasedPriceOneWeekFreeTrialToMonthly
  config.planIds.increasedPriceAnnual = envVars.PLAN_ID_INCREASED_PRICE_ANNUAL
    || config.planIds.increasedPriceAnnual
  config.planIds.increasedPriceOneWeekFreeTrialToAnnual = envVars
    .PLAN_ID_INCREASED_PRICE_ONE_WEEK_FREE_TRIAL_TO_ANNUAL
    || config.planIds.increasedPriceOneWeekFreeTrialToAnnual
  config.planIds.increasedPriceGaiaPlus = envVars.PLAN_ID_INCREASED_PRICE_GAIA_PLUS
    || config.planIds.increasedPriceGaiaPlus
  config.planIds.fourteenMonthsToAnnual = envVars.PLAN_ID_FOURTEEN_MONTHS_TO_ANNUAL
  || config.planIds.fourteenMonthsToAnnual
  config.planIds.fourteenMonthsToAnnual2 = envVars.PLAN_ID_FOURTEEN_MONTHS_TO_ANNUAL_2
  || config.planIds.fourteenMonthsToAnnual2
  config.planIds.fourteenMonthsToAnnual3 = envVars.PLAN_ID_FOURTEEN_MONTHS_TO_ANNUAL_3
  || config.planIds.fourteenMonthsToAnnual3
  config.planIds.fourteenMonthsToAnnual4 = envVars.PLAN_ID_FOURTEEN_MONTHS_TO_ANNUAL_4
  || config.planIds.fourteenMonthsToAnnual4
  config.planIds.fourteenMonthsToAnnual5 = envVars.PLAN_ID_FOURTEEN_MONTHS_TO_ANNUAL_5
  || config.planIds.fourteenMonthsToAnnual5
  config.planIds.fourteenMonthsToAnnual6 = envVars.PLAN_ID_FOURTEEN_MONTHS_TO_ANNUAL_6
  || config.planIds.fourteenMonthsToAnnual6

  // Features
  config.features = config.features || {}
  config.features.comments = parseEnvInt(
    envVars.FEATURES_COMMENTS,
    config.features.comments,
  )
  config.features.events.server = parseEnvInt(
    envVars.FEATURES_EVENTS_SERVER,
    config.features.events.server,
  )
  config.features.eventsPlus.v2 = parseEnvInt(
    envVars.EVENTS_PLUS_V2,
    config.features.eventsPlus.v2,
  )

  config.features.isVideoPluginReady = parseEnvInt(
    envVars.IS_VIDEO_PLUGIN_READY,
    config.features.isVideoPluginReady,
  )

  config.features.disableImpressionCap = parseEnvInt(
    envVars.FEATURES_DISABLE_IMPRESSION_CAP,
    config.features.disableImpressionCap,
  )
  config.features.filters.fitness = parseEnvInt(
    envVars.FEATURES_FILTERS_FITNESS,
    config.features.filters.fitness,
  )
  config.features.filters.original = parseEnvInt(
    envVars.FEATURES_FILTERS_ORIGINAL,
    config.features.filters.original,
  )
  config.features.filters.conscious = parseEnvInt(
    envVars.FEATURES_FILTERS_CONSCIOUS,
    config.features.filters.conscious,
  )
  config.features.gift.checkout = parseEnvInt(
    envVars.FEATURES_GIFT_CHECKOUT,
    config.features.gift.checkout,
  )
  config.features.gift.adyen = parseEnvInt(
    envVars.FEATURES_GIFT_CHECKOUT_ADYEN,
    config.features.gift.adyen,
  )
  config.features.gift.iconCTA = parseEnvInt(
    envVars.FEATURES_GIFT_ICON_CTA,
    config.features.gift.iconCTA,
  )
  config.features.checkout.velocityCheck = parseEnvInt(
    envVars.FEATURES_CHECKOUT_VELOCITY_CHECK,
    config.features.checkout.velocityCheck,
  )
  config.features.checkout.dataDome = parseEnvInt(
    envVars.FEATURES_CHECKOUT_DATA_DOME,
    config.features.checkout.dataDome,
  )
  config.features.checkout.planGridDefaultPlan = parseEnvInt(
    envVars.FEATURES_CHECKOUT_PLAN_GRID_DEFAULT_PLAN,
    config.features.checkout.planGridDefaultPlan,
  )
  config.features.checkout.adyenPaypal = parseEnvInt(
    envVars.FEATURES_CHECKOUT_ADYEN_PAYPAL,
    config.features.checkout.adyenPaypal,
  )
  config.features.checkout.adyenApplepay = parseEnvInt(
    envVars.FEATURES_CHECKOUT_ADYEN_APPLEPAY,
    config.features.checkout.adyenApplepay,
  )
  config.features.checkout.adyenGooglepay = parseEnvInt(
    envVars.FEATURES_CHECKOUT_ADYEN_GOOGLEPAY,
    config.features.checkout.adyenGooglepay,
  )
  config.features.checkout.asyncCheckout = parseEnvInt(
    envVars.FEATURES_CHECKOUT_ASYNC_CHECKOUT,
    config.features.checkout.asyncCheckout,
  )
  config.features.checkout.planGraphQL = parseEnvInt(
    envVars.FEATURES_CHECKOUT_PLAN_GRAPHQL,
    config.features.checkout.planGraphQL,
  )
  config.features.development.kitchenSink = parseEnvInt(
    envVars.FEATURES_DEVELOPMENT_KITCHEN_SINK,
    config.features.development.kitchenSink,
  )
  config.features.development.adyenTesting = parseEnvInt(
    envVars.FEATURES_DEVELOPMENT_ADYEN_TESTING,
    config.features.development.adyenTesting,
  )
  config.features.emailCapture = parseEnvInt(
    envVars.FEATURES_EMAIL_CAPTURE,
    config.features.emailCapture,
  )
  config.features.smartling = parseEnvInt(
    envVars.FEATURES_SMARTLING,
    config.features.smartling,
  )
  config.features.zendeskChat = parseEnvInt(
    envVars.FEATURES_ZENDESK_CHAT,
    config.features.zendeskChat,
  )
  config.features.marketing.proxyAnonymousHome = parseEnvInt(
    envVars.FEATURES_MARKETING_PROXY_ANONYMOUS_HOME,
    config.features.marketing.proxyAnonymousHome,
  )
  config.features.marketing.specialOfferPromoPopup = parseEnvInt(
    envVars.FEATURES_MARKETING_SPECIAL_OFFER_PROMO_POPUP,
    config.features.marketing.specialOfferPromoPopup,
  )
  config.features.marketing.proxyAnonymousSeries = parseEnvInt(
    envVars.FEATURES_MARKETING_PROXY_ANONYMOUS_SERIES,
    config.features.marketing.proxyAnonymousSeries,
  )
  config.features.marketing.proxyAnonymousPerson = parseEnvInt(
    envVars.FEATURES_MARKETING_PROXY_ANONYMOUS_PERSON,
    config.features.marketing.proxyAnonymousPerson,
  )
  config.features.marketing.proxyAnonymousVideo = parseEnvInt(
    envVars.FEATURES_MARKETING_PROXY_ANONYMOUS_VIDEO,
    config.features.marketing.proxyAnonymousVideo,
  )
  config.features.shareView = parseEnvInt(
    envVars.FEATURES_SHARE_VIEW,
    config.features.shareView,
  )
  config.features.player.detectLang = parseEnvInt(
    envVars.FEATURES_PLAYER_DETECT_LANG,
    config.features.player.detectLang,
  )
  config.features.player.gatedPreviews = parseEnvInt(
    envVars.FEATURES_PLAYER_GATED_PREVIEWS,
    config.features.player.gatedPreviews,
  )
  config.features.languageSelectGlobeAnonymous = parseEnvInt(
    envVars.FEATURES_LANGUAGE_SELECT_GLOBE_ANONYMOUS,
    config.features.languageSelectGlobeAnonymous,
  )
  config.features.languageSelectDialog = parseEnvInt(
    envVars.FEATURES_LANGUAGE_SELECT_DIALOG,
    config.features.languageSelectDialog,
  )
  config.features.languageSelect = parseEnvInt(
    envVars.FEATURES_LANGUAGE_SELECT,
    config.features.languageSelect,
  )
  config.features.languageSelectAnonymous = parseEnvInt(
    envVars.FEATURES_LANGUAGE_SELECT_ANONYMOUS,
    config.features.languageSelectAnonymous,
  )
  config.features.languageSelectFr = parseEnvInt(
    envVars.FEATURES_LANGUAGE_SELECT_FR,
    config.features.languageSelectFr,
  )
  config.features.languageSelectDe = parseEnvInt(
    envVars.FEATURES_LANGUAGE_SELECT_DE,
    config.features.languageSelectDe,
  )
  config.features.apiClient.omitLanguage = parseEnvInt(
    envVars.FEATURES_APICLIENT_OMIT_LANGUAGE,
    config.features.apiClient.omitLanguage,
  )
  config.features.userLanguage.esZendeskHelpLink = parseEnvInt(
    envVars.FEATURES_USER_LANGUAGE_ES_ZENDESK_HELP_LINK,
    config.features.userLanguage.esZendeskHelpLink,
  )
  config.features.anonymousUser.dataPrivacyCompliance = parseEnvInt(
    envVars.FEATURES_ANONYMOUS_USER_DATA_PRIVACY_COMPLIANCE,
    config.features.anonymousUser.dataPrivacyCompliance,
  )
  config.features.userAccount.fmtvMigrationDate = parseEnvInt(
    envVars.FEATURES_USER_ACCOUNT_FMTV_MIGRATION_DATE,
    config.features.userAccount.fmtvMigrationDate,
  )
  config.features.userAccount.cancelOffer = parseEnvInt(
    envVars.FEATURES_USER_ACCOUNT_CANCEL_OFFER,
    config.features.userAccount.cancelOffer,
  )
  config.features.userAccount.accountPause = parseEnvInt(
    envVars.FEATURES_USER_ACCOUNT_ACCOUNT_PAUSE,
    config.features.userAccount.accountPause,
  )
  config.features.userAccount.declinePlan303 = parseEnvInt(
    envVars.FEATURES_USER_ACCOUNT_DECLINE_PLAN_303,
    config.features.userAccount.declinePlan303,
  )
  config.features.userAccount.cancelV2 = parseEnvInt(
    envVars.FEATURES_USER_ACCOUNT_CANCEL_V2,
    config.features.userAccount.cancelV2,
  )
  config.features.userAccount.manageEmailCommunication = parseEnvInt(
    envVars.FEATURES_USER_ACCOUNT_MANAGE_EMAIL_COMMUNICATION,
    config.features.userAccount.manageEmailCommunication,
  )
  config.features.userAccount = config.features.userAccount || {}
  config.features.user = config.features.user || {}
  config.features.skunk150.relatedSeriesNids = parseEnvArray(
    envVars.FEATURES_SKUNK_150_RELATED_SERIES_NIDS,
    config.features.skunk150.relatedSeriesNids,
  ).map(parseEnvInt)

  config.features.showUserPortalSearchResults = parseEnvInt(
    envVars.FEATURES_SHOW_USER_PORTAL_SEARCH_RESULTS,
    config.features.showUserPortalSearchResults,
  )
  config.features.recipes.categoryTermId = parseEnvInt(
    envVars.FEATURES_RECIPES_CATEGORY_TERM_ID,
    config.features.recipes.categoryTermId,
  )
  config.features.meditation.categoryTermId = parseEnvInt(
    envVars.FEATURES_MEDITATION_CATEGORY_TERM_ID,
    config.features.meditation.categoryTermId,
  )
  config.features.filmFilters.enabled = parseEnvBool(
    envVars.FILM_FILTERS_ENABLED,
    config.features.filmFilters.enabled,
  )
  config.features.guidesEnabled = parseEnvInt(
    envVars.GUIDES_ENABLED,
    config.features.guidesEnabled,
  )
  config.features.zendeskChatOnCheckoutFlowEnabled = parseEnvBool(
    envVars.ZENDESK_CHAT_CHECKOUT_ENABLED,
    config.features.zendeskChatOnCheckoutFlowEnabled,
  )
  config.features.recommendedPractices.blacklistedTermIds = parseEnvArray(
    envVars.FEATURES_RECOMMENDED_PRACTICES_TERM_BLACKLIST,
    config.features.recommendedPractices.blacklistedTermIds,
  ).map(parseEnvInt)
  config.features.detailPageV2Tags = parseEnvInt(
    envVars.FEATURES_DETAIL_PAGE_V2_TAGS,
    config.features.detailPageV2Tags,
  )
  config.features.optimizely = parseEnvInt(
    envVars.FEATURES_OPTIMIZELY,
    config.features.optimizely,
  )
  config.features.adminAppCMSEnabled = parseEnvInt(
    envVars.ADMIN_APP_CMS_ENABLED,
    config.features.adminAppCMSEnabled,
  )
  config.features.ambassadorPayouts = parseEnvInt(
    envVars.FEATURES_AMBASSADOR_PAYOUTS,
    config.features.ambassadorPayouts,
  )
  config.features.eventsPlusVideoAndCopy = parseEnvInt(
    envVars.FEATURES_EVENTS_PLUS_VIDE_AND_COPY,
    config.features.eventsPlusVideoAndCopy,
  )
  config.features.zuoraOutage = parseEnvInt(
    envVars.FEATURES_ZUORA_OUTAGE,
    config.features.zuoraOutage,
  )
  config.features.accountSecurity = parseEnvInt(
    envVars.FEATURES_ACCOUNT_SECURITY,
    config.features.accountSecurity,
  )
  config.features.hubsTest = parseEnvInt(
    envVars.FEATURES_HUBS_TEST,
    config.features.hubsTest,
  )
  config.features.marketplace = parseEnvInt(
    envVars.FEATURES_MARKETPLACE,
    config.features.marketplace,
  )
  config.features.tilesV2Graphql = parseEnvInt(
    envVars.FEATURES_TILES_V2_GRAPHQL,
    config.features.tilesV2Graphql,
  )
  config.features.playlistAddRemoveGraphql = parseEnvInt(
    envVars.FEATURES_PLAYLIST_ADD_REMOVE_GRAPHQL,
    config.features.playlistAddRemoveGraphql,
  )
  config.features.gift.giftReminder = parseEnvInt(
    envVars.FEATURES_GIFT_REMINDER,
    config.features.gift.giftReminder,
  )
  config.features.navShopLinkV3 = parseEnvInt(
    envVars.FEATURES_NAV_SHOP_LINK_V3,
    config.features.navShopLinkV3,
  )
  config.features.partnerDashboard = parseEnvInt(
    envVars.FEATURES_PARTNER_DASHBOARD,
    config.features.partnerDashboard,
  )
  config.features.twoColumnsPlanTest = parseEnvInt(
    envVars.FEATURES_TWO_COLUMNS_PLAN_TEST,
    config.features.twoColumnsPlanTest,
  )
  config.features.optimizedImages = parseEnvInt(
    envVars.FEATURES_OPTIMIZED_IMAGES,
    config.features.optimizedImages,
  )
  config.features.detailPageV2Guides = parseEnvInt(
    envVars.FEATURES_DETAIL_PAGE_V2_GUIDES,
    config.features.detailPageV2Guides,
  )
  config.features.detailPageGraphQL = parseEnvInt(
    envVars.FEATURES_DETAIL_PAGE_GRAPHQL,
    config.features.detailPageGraphQL,
  )
  config.features.topicsPageV3 = parseEnvInt(
    envVars.FEATURES_TOPICS_PAGE_V3,
    config.features.topicsPageV3,
  )
  config.features.oneTrust = parseEnvInt(
    envVars.FEATURES_ONE_TRUST,
    config.features.oneTrust,
  )
  config.features.promotionGraphQL = parseEnvInt(
    envVars.FEATURES_PROMOTION_GRAPHQL,
    config.features.promotionGraphQL,
  )
  config.features.limitLocalTracking = parseEnvInt(
    envVars.FEATURES_LIMIT_LOCAL_TRACKING,
    config.features.limitLocalTracking,
  )
  config.features.sharePageV2 = parseEnvInt(
    envVars.FEATURES_SHARE_PAGE_V2,
    config.features.sharePageV2,
  )
  config.features.announcementModal.enabled = parseEnvInt(
    envVars.FEATURES_ANNOUNCEMENT_MODAL_ENABLED,
    config.features.announcementModal.enabled,
  )
  config.features.announcementModal.mediaId = parseEnvInt(
    envVars.FEATURES_ANNOUNCEMENT_MODAL_MEDIA_ID,
    config.features.announcementModal.mediaId,
  )
  config.features.gaiaPlusPage = parseEnvInt(
    envVars.FEATURES_GAIA_PLUS_PAGE,
    config.features.gaiaPlusPage,
  )
  config.features.modalCartAbandonV2 = parseEnvInt(
    envVars.FEATURES_MODAL_CART_ABANDON_V2,
    config.features.modalCartAbandonV2,
  )
  config.features.sharePageV3 = parseEnvInt(
    envVars.FEATURES_SHARE_PAGE_V3,
    config.features.sharePageV3,
  )
  config.features.yogaTeachersPageV2 = parseEnvInt(
    envVars.FEATURES_YOGA_TEACHERS_PAGE_V2,
    config.features.yogaTeachersPageV2,
  )
  config.features.granularEmailPreferences = parseEnvInt(
    envVars.FEATURES_GRANULAR_EMAIL_PREFERENCES,
    config.features.granularEmailPreferences,
  )
  config.features.checkoutFourteenMonths = parseEnvInt(
    envVars.FEATURES_CHECKOUT_FOURTEEN_MONTHS,
    config.features.checkoutFourteenMonths,
  )
  config.features.singlePageCheckout = parseEnvInt(
    envVars.FEATURES_SINGLE_PAGE_CHECKOUT,
    config.features.singlePageCheckout,
  )
  config.features.notificationsVideoPromotion = parseEnvInt(
    envVars.FEATURES_NOTIFICATIONS_VIDEO_PROMOTION,
    config.features.notificationsVideoPromotion,
  )
}

/**
 * Get the config values which have been set using init
 * @returns {Object} The config
 */
export function get () {
  return _cloneDeep(config)
}

init()
